import { RESULT_FILTERS } from "../results-const"

export default class ResultDefaultFilters {
  constructor(repo, data) {
    this.repo = repo
    this.data = data
  }

  get() {
    let filters = this.repo.get(RESULT_FILTERS)
    if(!filters) return this.data
    let _data = {...this.data}
    for(let filter in filters) {
      if(filter in _data) continue
      _data[filter] = filters[filter]
    }
    return _data
  }
}