import { 
  getMultipleQueryValueWithNumber, 
  getMultipleQueryValue, 
  transformQueryBoolean, 
  convertToDefaultDateFormat,
  convertToDefaultBackendFormat
} from "@/util"

export const FILTERS_ARGS = {
  EXAM_LOCATION: 'location',
  EXAM_DATE_FROM: 'exam_date_after',
  EXAM_DATE_TO: 'exam_date_before',
  PRODUCT: 'product_name',
  SEARCH: 'search',
  TYPE: 'type',
  COURSE: 'course'
}

export const FILTERS_ATTR = {
  EXAM_LOCATION: 'examLocation',
  EXAM_DATE_FROM: 'examDateFrom',
  EXAM_DATE_TO: 'examDateTo',
  EXAM_DATE_TO_FORMATTED: 'examDateToFormatted',
  COURSE: 'course'
}

export default class ResultsFiltersModel {
  constructor(filters) {
    this.filters = {
      [FILTERS_ATTR.EXAM_LOCATION]: getMultipleQueryValue(filters[FILTERS_ARGS.EXAM_LOCATION]),
      [FILTERS_ATTR.EXAM_DATE_TO]: filters[FILTERS_ARGS.EXAM_DATE_TO],
      [FILTERS_ATTR.EXAM_DATE_FROM]: filters[FILTERS_ARGS.EXAM_DATE_FROM],
      [FILTERS_ATTR.COURSE]: +filters[FILTERS_ARGS.COURSE],
      [FILTERS_ARGS.TYPE]: filters[FILTERS_ARGS.TYPE],
      course_date: filters.course_date,
      product: filters[FILTERS_ARGS.PRODUCT],
      search: filters[FILTERS_ARGS.SEARCH],
      
    }
    this.setWillComeToCourse(filters)
  }

  setWillComeToCourse(filters) {
    let willCome
    if('will_come_to_course' in filters) willCome = transformQueryBoolean(filters.will_come_to_course)
    else willCome = true
    this.filters.will_come_to_course = willCome ? 0 : 1 // index for vuetify tab
  }

  get [FILTERS_ATTR.EXAM_DATE_TO_FORMATTED]() {
    if(!this.filters[FILTERS_ATTR.EXAM_DATE_TO]) return ''
    return convertToDefaultDateFormat(this.filters[FILTERS_ATTR.EXAM_DATE_TO])
  }

  set [FILTERS_ATTR.EXAM_DATE_TO_FORMATTED](val) {
    this.filters[FILTERS_ATTR.EXAM_DATE_TO] = val
  }

  getApiData({filterByCourse, courseDate}) {
    const activeFilters = {};
    activeFilters[FILTERS_ARGS.EXAM_LOCATION] = this.filters[FILTERS_ATTR.EXAM_LOCATION]
    if(!filterByCourse) {
      activeFilters[FILTERS_ARGS.EXAM_DATE_TO] = this.filters[FILTERS_ATTR.EXAM_DATE_TO]
      activeFilters[FILTERS_ARGS.EXAM_DATE_FROM] = this.filters[FILTERS_ATTR.EXAM_DATE_TO]
    }
    else {
      activeFilters[FILTERS_ARGS.COURSE] = this.filters[FILTERS_ATTR.COURSE]
      activeFilters.course_date = courseDate
    }
    activeFilters[FILTERS_ARGS.SEARCH] = this.filters.search
    if (this.filters.product) {
      activeFilters[FILTERS_ARGS.PRODUCT] = this.filters.product
    }
    if (this.filters.type) {
      activeFilters[FILTERS_ARGS.TYPE] = this.filters.type
    }
    return activeFilters;
  }

  setDefaultExamDate() {
    let now = Date.now()
    this.filters[FILTERS_ATTR.EXAM_DATE_TO] = convertToDefaultBackendFormat(now)
  }
}