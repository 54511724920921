<template lang="pug">
v-container(fluid).re-filters
  v-row(wrap)
    v-col(cols="12" md="3" tile)
      filter-autocomplete-loaded(
        v-model="model.filters[FILTERS_ATTR.EXAM_LOCATION]"
        :getItems="getExamLocations"
        multiple
        showCloseMenuBtn
        closeOnInputClick
        label="Location"
        hideDetails
        showSelectedIcon
        testName="res-location-input"
        :redirectName="$ROUTER_NAMES.LOGIN_CRM"
      )
    v-col(cols="12" md="3" tile)
      datepicker(
        v-model="model.filters[FILTERS_ATTR.EXAM_DATE_TO]"
        :disabled="state.filterByCourse"
        hideDetails
        testName="res-date-to"
        label="Exam date"
      )
      v-switch(v-model="state.filterByCourse" label="Filter by course?")
      datepicker(
        v-model="courseDate"
        :disabled="!state.filterByCourse"
        @input="loadCoursesAfterClick(courseDate)"
        hideDetails
        label="Selected course date"
        :max="disableFutureDates"
      )
      autocompleteChecked(
        v-model="model.filters[FILTERS_ATTR.COURSE]"
        :multiple="false"
        showCloseMenuBtn
        :disabled="!state.filterByCourse"
        :items="courses"
        item-text="label"
        item-value="id"
        label="Course"
      )

    v-col(cols="12" md="3" tile)
      filter-autocomplete-loaded(
        v-model="model.filters.product"
        :getItems="getProductNames"
        label="Product name"
        itemText="name"
        showClearBtn
        itemValue="name"
        showSelectedIcon
        hideDetails
        showCloseMenuBtn
        closeOnInputClick
        :redirectName="$ROUTER_NAMES.LOGIN_CRM"
      )
    v-col(cols="12" md="3" tile)
      filter-autocomplete-loaded(
        v-model="model.filters.type"
        :getItems="getCbrTypes"
        label="Type"
        showClearBtn
        showSelectedIcon
        hideDetails
        showCloseMenuBtn
        closeOnInputClick
        :redirectName="$ROUTER_NAMES.LOGIN_CRM"
      )


  v-btn(outlined data-test="res-submit" @click="checkOnValid").btn-common Apply
  search(
    v-model="model.filters.search"
    placeholder="Search by candidate data"
    @change="checkOnValid"
  ).mt-3.res-search
  div.flex.justify-between.mt-3.mb-3.flex-wrap
    monitoring(@monitoringStatusChanged="isMonitoringStarted = $event")
    v-btn.get-results-btn.btn-common(outlined @click="getResults" :disabled="resultsDisabled || isMonitoringStarted") Get Results

  div(v-if="statistic").results-statistic
    div.results-statistic__item
      v-tooltip(top)
        template(v-slot:activator="{on}")
          span.results-statistic__count(v-on="on") {{ statistic.total }}/
        span Students
    div.results-statistic__item
      v-tooltip(top)
        template(v-slot:activator="{on}")
          span.results-statistic__count(v-on="on") {{ statistic.total_present }}/
        span Presentie
    div.results-statistic__item
      v-tooltip(top)
        template(v-slot:activator="{on}")
          span.results-statistic__count(v-on="on")  {{ statistic.total_passed }}/
        span Passed

    div.results-statistic__item
      v-tooltip(top)
        template(v-slot:activator="{on}")
          span.results-statistic__count(v-on="on") {{ statistic.total_failed }}/
        span Failed
    div.results-statistic__item
      v-tooltip(top)
        template(v-slot:activator="{on}")
          span.results-statistic__count(v-on="on") {{ statistic.total_not_fetched }}/
        span Not fetched
      div.results-statistic__item
      v-tooltip(top)
        template(v-slot:activator="{on}")
          span.results-statistic__count(v-on="on") {{ statistic.total_not_came }}
        span Afwezig
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import FiltersModel, { FILTERS_ATTR } from '../core/models/ResultsFiltersModel.js'
import { CBR_TYPES, RESULT_FILTERS } from '../core/results-const'
import ErrorMixin from '@/mixins/errors.mixin'
import ResultDefaultFilters from '../core/models/resultDefaultFilters'
import Repo from '@/services/api/util/auth-repo-local-storage'
import { RESULTS_TAB } from '../core/models/resultsTabModel.js'

export const state = Vue.observable({
  resultsLoading: false,
  filterByCourse: false
})

export default {
  mixins: [ErrorMixin],

  props: {
    listCount: Object,
    statistic: Object
  },

  data: () =>({
    model: null,
    FILTERS_ATTR: FILTERS_ATTR,
    resultsDisabled: false,
    isMonitoringStarted: false,
    filterByCourse: state.filterByCourse,
    courseDate: null,
    state: {
      filterByCourse: false
    }
  }),

  created() {
    this.init()
  },

  computed: {
    ...mapGetters({
      courses: 'allCourses/items',
      course: 'results/course'
    }),

    disableFutureDates() {
      return '2021-07-29'
    },
  },

  async mounted() {
    this.checkOnFilterByCourse()
    await this.setDefaultQuery()
    if(this.courseDate) this.loadCourses(this.courseDate)
  },

  inject: ['getDefaultPagination', 'svc'],

  methods: {
    ...mapActions({
      getExamLocations: 'crmExamLocation/list',
      getProductNames: 'crmProductNames/list',
      loadAllCourses: 'allCourses/list'
    }),

    async getResults() {
      try {
        this.resultsDisabled = true;
        await this.svc.loadResults({query: {...this.getDefaultPagination(), ...this.model.getApiData({
          courseDate: this.courseDate, filterByCourse: this.state.filterByCourse, ordering: this.$route.query.ordering
        })}})
        this.$notify({type: 'success', text: 'Get results request send'})
        state.resultsLoading = true
      } catch (error) {
        this.processError(error);
      } finally {
        setTimeout(() => {
          this.resultsDisabled = false;
        }, 2000)
      }
    },

    getCbrTypes() {
      return Object.values(CBR_TYPES)
    },

    init() {
      this.repo = new Repo()
      let query = new ResultDefaultFilters(this.repo, this.$route.query).get()
      this.model = new FiltersModel(query)
    },

    async setDefaultQuery() {
      this.setDefaultExamDate()
      await this.filtersChanged()
    },

    setDefaultExamDate() {
      if(this.model.filters[FILTERS_ATTR.EXAM_DATE_TO]) return
      this.model.setDefaultExamDate()
    },

    async setQuery() {
      try {
        await this.$router.replace({query: {
          tab: this.$route.query.tab,
          ...this.getDefaultPagination(),
          ...this.model.getApiData({courseDate: this.courseDate, filterByCourse: this.state.filterByCourse}),
          ordering: this.$route.query.ordering
        }})
      } catch (error) {
        console.log(error)
      }
    },

    async filtersChanged() {
      await this.setQuery()
      this.$emit('filterChanged')
    },

    saveToRepo() {
      this.repo.store(RESULT_FILTERS, this.$route.query)
    },

    checkOnFilterByCourse() {
      if(this.model.filters.course && this.model.filters.course_date) {
        this.state.filterByCourse = true
        this.courseDate = this.model.filters.course_date
      }
    },

    loadCoursesAfterClick(date) {
      this.model.filters[FILTERS_ATTR.COURSE] = null
      this.loadCourses(date)
    },

    async loadCourses(date) {
      try {
        await this.loadAllCourses({
          query: {
            date_after: date,
            date_before: date
          }
        })
      } catch (error) {
        this.processError(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
        });
      }
    },

    async checkOnValid() {
      if(this.state.filterByCourse && (!this.courseDate || !this.model.filters[FILTERS_ATTR.COURSE]))
        return this.$notify({type: 'error', text: "Course date or course not selected"})
      await this.filtersChanged()
      this.saveToRepo()
    }
  },

  components: {
    FilterAutocomplete: () => import('@/app/admin/components/FilterAutocompleteChecked.vue'),
    Datepicker: () => import('@/app/admin/components/CrmDatepicker.vue'),
    FilterAutocompleteLoaded: () => import('@/components/global/FilterAutocomplete.vue'),
    autocompleteChecked: () => import('@/app/admin/components/FilterAutocompleteChecked.vue'),
    Search: () => import('@/components/global/Search.vue'),
    monitoring: () => import('./ResultsMonitoring.vue'),
    cmServiceProvider: () => import('@/app/admin/modules/course_managment/components/CMServiceProvider.vue')
  }
}
</script>

<style lang="scss" scoped>
.res-search {
  box-shadow: 0 1px 1px 0 #ccc;
}

.get-results-btn {
  margin-top: 8px;
  @media(min-width: 767px) {
    margin-top: 0;
  }
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.re-filters {
  padding-left: 14px;
  padding-right: 14px;
}

.search::v-deep {
  &.v-input .v-text-field__slot input {
    padding: 8px 0 8px;
  }
}

.result-tab::v-deep .v-slide-group__prev {
  display: none !important;
}

.results-statistic {
  @media(min-width: 768px) {
    text-align: right;
  }

  &__item {
    display: inline-block;
  }

  &__count {
    font-weight: bold;
    cursor: pointer;
  }
}

.results-filters-bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &::v-deep {
    .v-tabs {
      width: auto;
    }
  }
}
</style>
